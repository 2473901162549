@import "../../../styles/variables.scss";
.matched-radio-wrap {
    h3 {
        font-size: 20px;
    }
  .matched-radio {
    label {
      cursor: pointer;
      span {
        font-size: 20px;
        width: 40px;
        height: 40px;
      }
      input {
        display: none;
      }
      input:checked + span {
        border: solid 5px $yellow_cl;
      }
    }
  }
}
