@font-face {
    font-family: 'Segoe UI';
    src: url('../assets/fonts/SegoeUI-BoldItalic.woff2') format('woff2'),
        url('../assets/fonts/SegoeUI-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Segoe UI';
    src: url('../assets/fonts/SegoeUI-Bold.woff2') format('woff2'),
        url('../assets/fonts/SegoeUI-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Segoe UI';
    src: url('../assets/fonts/SegoeUI-Italic.woff2') format('woff2'),
        url('../assets/fonts/SegoeUI-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Segoe UI';
    src: url('../assets/fonts/SegoeUI.woff2') format('woff2'),
        url('../assets/fonts/SegoeUI.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }