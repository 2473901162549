.start-parent {
    .cont-heading {
        font-size: 25px;
        font-weight: 500;
        margin-bottom: 32px;
    }

    .button-parent {
        .big-yellow-btn {
            min-height: 75px;
            color: #257ABB;
            background-color: #FFCD07;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 35px;
            padding: 0 70px;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 2px;
            transition: all 0.5s;
            z-index: 1;
            &:hover {
                background-color: #fff!important;
                color: #FFCD07;
                border-color: #fff!important;
            }
        }
    }
}