.top-header {
    .cont-img {
        height: 60px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        &.sap-siemens-parent {
            gap: 32px;
            img{
                max-width: 200px; 
            }
        }
    }
}

.heading-parent {
    .cont-img {
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        
    }

    .cont-text {
        h1 {
            color: $babyBlue_cl;
            text-align: center;
        }
        
    }
}