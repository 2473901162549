* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  
  body {
    background-color: $blue_cl;
    font-family: 'Segoe UI';
    color: #fff;
  }
  
  // genral clases 
  
  .w-90 {
    width: 90%;
    margin: 0 auto;
  }

  .w-80 {
    width: 80%;
    margin: 0 auto;
  }

  .w-70 {
    width: 70%;
    margin: 0 auto;
  }
  
  img {
    max-width: 100%;
  }
  
  .bold-text {
    font-weight: bold;
  }

  .yellow-text {
    color: $yellow_cl;
  }

  .orange-text {
    color: $orange_cl;
  }

  .blue-text {
    color: $blue_light_cl;
  }

  .green-text {
    color: $green_cl;
  }

  .btn-primary {
    &:hover {
      background-color: $blue_cl!important;
    }
  }

  .relative-parent {
    position: relative;
    &.white-bg {
      background: #fff;
    }
  }

.white-input {
    width: 110px;
    background-color: #fff;
    border: none;
    height: 60px;
    font-size: 20px;
    color: $yellow_cl;
    border-radius:56px;
    text-align: center;
    padding: 0 28px;
    
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
}

.checkbox-parent-white {
  gap: 24px;
  background-color: #fff;
  height: 86px;
  font-size: 30px;
  padding: 0 24px;
  border-radius: 56px;
  input[type="checkbox"] {
    border-radius: 5px;
    appearance: none;
    width: 32px;
    height: 32px;
    background-color: $yellow_cl;
    cursor: pointer;
    color: #fff;
    &:checked {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
  }
}

.absolute-percentage {
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
}

select.white-select {
  height: 56px;
  font-size: 26px;
  border-bottom: 5px solid;
  padding: 0 32px 0 16px;
  appearance: none;
  //background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 330 330' style='enable-background:new 0 0 330 330;' xml:space='preserve'%3E%3Cpath id='XMLID_225_' d='M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z'/%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
  background-position: 95% center;
  background-repeat: no-repeat;
  background-size: 24px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:focus{
    outline: none;
    
  }
  &.orange-cl {
    color: $orange_cl;
    border-color:$orange_cl;
  }
  &.blue-cl {
    color: $blue_light_cl;
    border-color:$blue_light_cl;
  }
  &.green-cl {
    color: $green_cl;
    border-color:$green_cl;
  }
}

.abosulte-icon {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
  &.orange-cl {
    background-color: $orange_cl;
  }
  &.blue-cl {
    background-color: $blue_light_cl;
  }
  &.green-cl {
    background-color: $green_cl;
  }
}

.select-parent {
  margin-bottom: 40px;
  width: 100%;
  position: relative;
  p {
    font-size: 22px;
  }
  label {
    font-size: 22px;
    margin-bottom: 16px;
  }
  .cont-select {
    width: 35%;
    @media (max-width:480px){
      width: 100%;
    }
    select {
      width: 100%;
      font-size: 18px;
    }
  } 
  &::after {
    content: '';
    width: 100%;
    height: 5px;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 27px;
    z-index: -1;
  }

  &.no-after {
    &::after {
      display: none;
    }
  }

  &.has-disclamer {
    .cont-select {
      width: 100%;
      input {
        width: 35%;
        @media (max-width:480px){
          width: 100%;
        }
      }
      .disclaimer-text {
        font-size: 16px;
        margin-right: 24px;
      }
    }
  }
}

.colored-input {
  height: 56px;
  background-color: #fff;
  border-bottom: 5px solid;
  padding: 0 16px;
  width: 100%;
  font-size: 18px;
  &:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
  }
  &.orange-cl {
    color: $orange_cl;
    border-color: $orange_cl;
  }
  &.green-cl {
    color: $green_cl;
    border-color: $green_cl;
  }
  &.blue-cl {
    color: $blue_light_cl;
    border-color: $blue_light_cl;
  }
  &::placeholder {
    color: inherit;
  }
}

.btn:disabled {
  background: darkgray;
    opacity: 1;
    color: dimgray;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



::-webkit-scrollbar-track { 
  background: $blue_cl!important;
  border-radius: 10px;
}


::-webkit-scrollbar-thumb {
  background: $yellow_cl!important; 
  width: 3px;
  border-radius: 10px;
}

.white-text {
  color: #fff;
}


// hide steps on landing page instead of using routing 
.landingHeaderParent + .top-steps {
  display: none!important;
}