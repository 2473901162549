@import "../../styles/variables.scss";

.top-steps {
  .tail {
    height: 19px;
    width: 100%;
    margin: 0 -15px;
    border-width: 1px 0;
    border-style: solid;
    border-color: $babyBlue_cl;
  }
  .step {
    .img-wrap {
      height: 150px;
      img {
        object-fit: contain;
        max-width: 100px;
        max-height: 100%;
      }

    }
    // &.step-4 {
    //   h3 {
    //     transform: translateX(90px);
    //   }
    // }
    &.active +
        .tail {
        background-color: $babyBlue_cl;
}
    
    h3 {
      font-size: 18px;
      font-weight: bold;
      color: white;
      top:100%;
      @media (min-width:1024px) {
        white-space: nowrap;
      }
    }
  }
}
