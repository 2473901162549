@import "../../../styles/variables.scss";
.answers-parent {
    margin: 80px 0;

    label {
      cursor: pointer;
      span {
        font-size: 20px;
        min-width: 170px;
        height: 80px;
        background-color: #fff;
        color: $yellow_cl;
        border-radius: 50px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            background-color: $yellow_cl!important;
            color: #fff;
        }
      }
      input {
        display: none;
      }
      input:checked + span {
        background-color: $yellow_cl;
        color: #fff;
      }
    }
  
}
