.landing-parent {
    .cont-text {
        color: #fff;
        font-size: 16px;
        p:not(:last-child) {
            margin-bottom: 40px;
        }
    }

    .button-parent {
        position: relative;
        margin-top: 48px; 
        .big-yellow-btn {
            min-height: 75px;
            color: $blue_cl;
            background-color: $yellow_cl;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 35px;
            padding: 0 70px;
            font-size: 16px;
            font-weight: bold;
            transition: all 0.5s;
            z-index: 1;
            &:hover {
                background-color: #fff;
                color: $yellow_cl;
            }
            
        }
        &::after {
            content: '';
            background-color: #fff;
            width: 100%;
            height: 5px;
            position:absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .social-links-parent {
        // position: fixed;
        right: 32px;
        bottom: 32px;
        width: fit-content;
        gap:16px;
        p {
            color: #fff;
            margin-bottom: 0;
        }

        .icons-parent {
            display: flex;
            gap: 12px;
            padding: 8px;
            background-color: $blue_cl;
            .follow-icon {
                width: 24px;
                height: 24px;
                background-color: $blue_cl;
                color: #fff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                transition: all 0.5s;
                &:hover {
                    background-color: #fff;
                    color: $blue_cl;
                    font-size: 14px;
                }
            }
        }
    }
}