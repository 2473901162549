@import "../../styles/variables.scss";
.left-image-block {
  .row {
    font-size: 18px;
    img {
        max-height: 180px;
    }
}
.content-related {
    border-top: white solid 6px;
    h2 {
        font-size: 28px;
    }
    p {
        font-size: 24px;
        font-style: italic;
        color: $yellow_cl;
    }
}
}