@import "../../../styles/variables.scss";
.next-previous {
    a {
        &.previous{
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='160.338' height='156.276' viewBox='0 0 160.338 156.276'%3E%3Cpath id='Icon_awesome-arrow-right' data-name='Icon awesome-arrow-right' d='M92.166,13.115,84.222,5.17a8.553,8.553,0,0,0-12.131,0L2.523,74.7a8.553,8.553,0,0,0,0,12.131L72.09,156.4a8.553,8.553,0,0,0,12.131,0l7.944-7.944a8.6,8.6,0,0,0-.143-12.275L48.9,95.1H151.749a8.568,8.568,0,0,0,8.589-8.589V75.06a8.568,8.568,0,0,0-8.589-8.589H48.9L92.023,25.389A8.536,8.536,0,0,0,92.166,13.115Z' transform='translate(0 -2.647)' fill='%23fff'/%3E%3C/svg%3E%0A");}
        &.next {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='160.338' height='156.276' viewBox='0 0 160.338 156.276'%3E%3Cpath id='Icon_awesome-arrow-right' data-name='Icon awesome-arrow-right' d='M68.172,13.115,76.116,5.17a8.553,8.553,0,0,1,12.131,0L157.815,74.7a8.553,8.553,0,0,1,0,12.131L88.248,156.4a8.553,8.553,0,0,1-12.131,0l-7.944-7.944a8.6,8.6,0,0,1,.143-12.275L111.437,95.1H8.589A8.568,8.568,0,0,1,0,86.511V75.06a8.568,8.568,0,0,1,8.589-8.589H111.437L68.315,25.389A8.536,8.536,0,0,1,68.172,13.115Z' transform='translate(0 -2.647)' fill='%23fff'/%3E%3C/svg%3E%0A");
            background-position: right;
        &.last-step {
            background: none;
        }
        }

        background-repeat: no-repeat;
        background-size: contain;

        button {
            background-color: $yellow_cl;
            color: $blue_cl;
            font-size: 16px;
            border-radius: 15px;
        //   padding: 35px 50px;
            
        }
    }

    .last-step {
        button {
            background-color: $yellow_cl;
            color: $blue_cl;
            font-size: 16px;
            border-radius: 15px;
            
        }
    }
}
