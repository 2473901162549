.button-parent {
    .big-yellow-btn {
        min-height: 56px;
        color: #257ABB;
        background-color: #FFCD07;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 35px;
        padding: 0 70px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 2px;
        transition: all 0.5s;
        z-index: 1;
        margin: 0 auto;
        &:hover {
            background-color: #fff!important;
            color: #FFCD07;
            border-color: #fff!important;
        }
    }
}