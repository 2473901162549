@import "../../../styles/variables.scss";


.vertical-radio-wrap {
    gap: 40px;
    font-size: 20px;
    input[type="radio"] {
        width: 50px;
        appearance: none;
        height: 50px;
        border-radius: 50%;
        background-color: $yellow_cl;
        border: none;
        cursor: pointer;
        &:checked {
            background-color: #fff;
            border: 13px solid $yellow_cl; 
        }
    }
}